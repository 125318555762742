<template>
  <div id="round-bg" style="min-height: 100vh">
    <div class="interests-table-content">
      <div class="interests-table-header">
        <div>
          <h1>{{ $t("allocations") }}</h1>
          <p>{{ $t("interest_table_sub_title") }}</p>
        </div>
        <div class="interests-table-header-btn-new-allocation">
          <NewDxaButton
            :outline="false"
            :title="$t('create_new_allocation')"
            @btnFunction="() => console.log('this is a function')"
          />
        </div>
      </div>
      <div class="interest-table-container-table">
        <AllocationsTableComponent />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";

import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import AllocationsTableComponent from "../components/AllocationsTableComponent";

export default {
  name: "AllocationsTable",

  components: {
    NewDxaButton,
    AllocationsTableComponent,
  },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    companies: [],
    selectedCompany: null,
    tab: 0,
    update: 0,
    AllocationSummary: {
      CompanyId: null,
      InitialDate: null,
      FinalDate: null,
      Currency: null,
    },
    confirmedSummary: [],
    pendingSummary: [],
    totalSummary: [],
    formatCurrency: formatCurrency,
    CurrencyEnum: CurrencyEnum,
    currencyOptions: [
      {
        text: "R$",
        value: CurrencyEnum.BRL,
      },
      {
        text: "US$",
        value: CurrencyEnum.USD,
      },
    ],
  }),
  async created() {
    await this.getCompanies();
  },
  computed: {
    StatusOptions() {
      return [
        this.$tc("interest_status_pending", 1),
        this.$tc("interest_status_approved", 1),
        this.$tc("interest_status_declined", 1),
        this.$t("interest_status_waiting_investor"),
      ];
    },
  },
  methods: {
    getCompanies() {
      this.apiService.getRequest("company/list").then((response) => {
        this.companies = JSON.parse(response.message);
      });
    },
    async getCompanyBalance() {
      this.loading = true;
      this.AllocationSummary.CompanyId = !!this.selectedCompany
        ? this.selectedCompany.CompanyId
        : null;

      await this.apiService
        .postRequest(
          `companyinterest/company-balance/filter`,
          this.AllocationSummary
        )
        .then((resp) => {
          this.confirmedSummary = resp.content[0].totalRequestConfirmed;
          this.pendingSummary = resp.content[0].totalRequestPending;
          this.totalSummary = resp.content[0].total;
        })
        .catch((error) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
      this.loading = false;
    },
    cleanFilters() {
      this.AllocationSummary.InitialDate = null;
      this.AllocationSummary.FinalDate = null;
      this.selectedCompany = null;
      this.confirmedSummary = 0;
      this.pendingSummary = 0;
      this.totalSummary = 0;
      this.update++;
      this.AllocationSummary.Currency = null;
    },
  },
};
</script>

<style scoped>
.value-box-flex {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.value-box-items > span {
  display: block;
  font-weight: bold;
}

/* novos estilos */
#round-bg {
  position: absolute;
  top: -75px;
  width: 100%;
  min-height: 100vh !important;
  background-color: var(--white);
  height: unset;
}

.interests-table-content {
  margin-top: calc(75px + 20px);
  width: 100%;
  padding: 0 60px;
  background-color: var(--white);
  padding-bottom: 40px;
}

.interests-table-header {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.interests-table-header > div h1 {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 8px;
}

.interests-table-header > div p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #535353;
}

.interests-table-header-btn-new-allocation {
  max-width: 200px;
}

.interest-table-container-table {
  margin-top: 32px;
}
</style>
